export function useApiTravelerProfile() {
  const { $api } = useNuxtApp();

  const defaultQuery = {};

  const defaultHeaders = {
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*',
  };

  const defaultParams = {};

  const getSocialPreferences = async (userIdToken) => {
    let result = ref(null);

    const socialPreferencesHeaders = {
      ...defaultHeaders,
      Authorization: `Bearer ${userIdToken}`,
    };

    const callKey = `getSocialPreferences${userIdToken}`;
    const endpoint = 'user/social-preferences';

    result = ref(await $api.tpapi(callKey, endpoint, socialPreferencesHeaders, defaultQuery, defaultParams));
    return result?.value || null;
  };

  const updateSocialPreferences = async (userIdToken, acceptSocialTCs, privateProfile) => {
    let result = ref(null);

    const extraOptions = {
      method: 'PUT',
      body: {
        acceptSocialTCs,
        privateProfile,
      },
    };

    const socialPreferencesHeaders = {
      ...defaultHeaders,
      Authorization: `Bearer ${userIdToken}`,
    };

    const callKey = null;
    const endpoint = 'user/social-preferences';

    result = ref(
      await $api.tpapi(callKey, endpoint, socialPreferencesHeaders, defaultQuery, defaultParams, extraOptions),
    );
    return result?.value || null;
  };

  return {
    getSocialPreferences,
    updateSocialPreferences,
  };
}
