<script setup>
import { WdsButtonGhost, WdsButtonLink, WdsButtonPrimary, WdsPopup } from '@wds/components';

// COMPOSABLES
const nuxtApp = useNuxtApp();
const runtimeConfig = useRuntimeConfig();
const envData = runtimeConfig?.public;
const dateTime = useDateTime();
const userAccount = useAccount();
const {
  isExtraSmallScreen,
  isBelowMediumScreen,
} = useMq();
const { useUrl } = useUrls();
const { $tracking } = nuxtApp;

// APIS
const travelerProfileApi = useApiTravelerProfile();

// CONSTANTS
const SOCIAL_PERMISSIONS_COOKIE_NAME = 'notShowSocialPermissions';
const SOCIAL_PERMISSIONS_COOKIE_MAXAGE = 6;
const APP_DOWNLOAD_LINK = 'https://hostelworld.onelink.me/IdiK/hsanwylw';

// VARIABLES
const socialPermissionsCookie = useCookie(SOCIAL_PERMISSIONS_COOKIE_NAME, {
  domain: envData?.COOKIE_DOMAIN,
  path: '/',
  sameSite: 'Strict',
  secure: true,
  expires: dateTime.addMonthsToDate(null, SOCIAL_PERMISSIONS_COOKIE_MAXAGE, 'date'),
});

// ## DATA
const showPopup = ref(false);
const hasAccepted = ref(false);
const isPrivateProfile = ref(false);

// ## COMPUTED
const showSecondScreen = computed(() => {
  return toValue(hasAccepted) && toValue(isExtraSmallScreen);
});

const getTermsAndConditionsLink = computed(() => {
  return useUrl.getGenericPageUrl('hosteltermsandconditions');
});

const getSecurityPrivacyLink = computed(() => {
  return useUrl.getGenericPageUrl('securityprivacy');
});

// ## METHODS
function togglePopup(val) {
  showPopup.value = !!val;
}

function handleAppDownload() {
  $tracking.triggerSocialPermissionsEventAction({
    action: 'Social Permission Joined Modal Clicked',
    social_permission_button_clicked: 'open the app',
  });
}

function setSocialPermissionsCookie() {
  socialPermissionsCookie.value = true;
}

function handleSocialPermissionsClose() {
  if (showPopup.value) {
    setSocialPermissionsCookie();
    togglePopup(false);

    $tracking.triggerSocialPermissionsEventAction({ action: 'Social Permission Modal Closed' });
  }
}

function handleTermsAndConditionsClick() {
  $tracking.triggerSocialPermissionsEventAction({ action: 'Social Permission Modal Terms Clicked' });
}

function handleSecurityPrivacyClick() {
  $tracking.triggerSocialPermissionsEventAction({ action: 'Social Permission Modal Privacy Clicked' });
}

function handleNoParticipationClick() {
  setSocialPermissionsCookie();
  togglePopup(false);

  $tracking.triggerSocialPermissionsEventAction({
    action: 'Social Permission Modal Clicked',
    social_permission_button_clicked: 'maybe later',
  });
}

async function handleAcceptTerms() {
  // Register user social consent on traveler profile api
  await travelerProfileApi.updateSocialPreferences(
    userAccount.getLoggedInUser.value?.token,
    true,
    isPrivateProfile.value,
  );

  hasAccepted.value = true;

  // Refresh user token
  await userAccount.refreshUserToken();

  if (!toValue(isExtraSmallScreen)) {
    togglePopup(false);
    $tracking.triggerSocialPermissionsEventAction({
      action: 'Social Permission Modal Clicked',
      social_permission_button_clicked: 'accept',
    });
  } else {
    $tracking.triggerSocialPermissionsEventAction({ action: 'Social Permission Joined Modal Displayed' });
  }
}

function handleAcceptTermsContinue() {
  setSocialPermissionsCookie();
  togglePopup(false);

  $tracking.triggerSocialPermissionsEventAction({
    action: 'Social Permission Joined Modal Clicked',
    social_permission_button_clicked: 'continue',
  });
}

function displaySocialPermissionsPopup() {
  return toValue(userAccount.hasLoggedInUser) && !toValue(socialPermissionsCookie);
}

onMounted(async () => {
  if (displaySocialPermissionsPopup()) {
    const userAuthToken = userAccount.getLoggedInUser.value?.token;

    if (userAuthToken) {
      const userSocialProfile = await travelerProfileApi.getSocialPreferences(userAuthToken);

      isPrivateProfile.value = userSocialProfile?.privateProfile || false;
      if (userSocialProfile?.acceptSocialTCs) {
        // User already accepted the social T&Cs: Add cookie to browser.
        setSocialPermissionsCookie();
      } else {
        // User has not responded to the social T&Cs: Display social permissions popup.
        $tracking.triggerSocialPermissionsEventAction({ action: 'Social Permission Modal Displayed' });
        togglePopup(true);
      }
    }
  }
});
</script>

<template>
  <WdsPopup
    v-if="displaySocialPermissionsPopup()"
    :is-open="showPopup"
    :apply-sheet="isBelowMediumScreen"
    :sheet-height="0"
    close-button
    :head-divider="false"
    @popup-close="handleSocialPermissionsClose"
  >
    <template #popupContent>
      <div class="social-permissions-body">
        <template v-if="showSecondScreen">
          <img
            alt="social confirmation"
            src="@/public/images/social-consent/SocialConfirm.jpg"
          />
          <div class="social-permissions-title">
            {{ $t('t_YOUJOINED') }}
          </div>
          <div class="social-permissions-description">
            {{ $t('t_YOUARENOWPART') }}
          </div>
          <WdsButtonPrimary
            class="social-permissions-open-app"
            :href="APP_DOWNLOAD_LINK"
            :text="$t('t_OPENAPP')"
            :full-width="true"
            target="_blank"
            @click="handleAppDownload"
          />
          <WdsButtonGhost
            class="social-permissions-continue"
            :text="$t('t_CLIMATEOFFSETCONTINUE')"
            :full-width="true"
            @click="handleAcceptTermsContinue"
          />
        </template>
        <template v-else>
          <img
            class="social-permissions-image"
            alt="social banner"
            src="@/public/images/social-consent/SocialImage.jpg"
            width="520"
            height="160"
          />
          <div class="social-permissions-title">
            {{ $t('t_MEETYOURPEOPLE') }}
          </div>
          <div class="social-permissions-description">
            {{ $t('t_SIGNUPSOCIALTERMS') }}
          </div>
          <div class="social-permissions-click-to-agree">
            {{ $t('t_CLICKTOAGREE') }}
          </div>
          <div class="social-permissions-legal-links">
            <WdsButtonLink
              class="social-permissions-link terms-link"
              :href="getTermsAndConditionsLink"
              :text="$t('t_TERMS')"
              target="_blank"
              @click="handleTermsAndConditionsClick()"
            />
            <WdsButtonLink
              class="social-permissions-link privacy-link"
              :href="getSecurityPrivacyLink"
              :text="$t('t_PRIVACYPOLICY')"
              target="_blank"
              @click="handleSecurityPrivacyClick()"
            />
          </div>
          <WdsButtonPrimary
            class="social-permissions-accept"
            :text="$t('t_ACCEPT')"
            @click="handleAcceptTerms"
          />
          <WdsButtonLink
            class="social-permissions-link social-permissions-no-participation"
            :text="$t('t_IDONTWANTTOPARTICIPATE')"
            @click="handleNoParticipationClick()"
          />
        </template>
      </div>
    </template>
  </WdsPopup>
</template>

<style scoped lang="scss">
.social-permissions-body {
  padding: 0 $wds-spacing-m $wds-spacing-m;
  color: $wds-color-ink-darker;

  .social-permissions-image {
    margin: 0 auto;
  }

  .social-permissions-title {
    color: $wds-color-ink-dark;
    margin: $wds-spacing-m 0;

    @include title-3-bld;
  }

  .social-permissions-description,
  .social-permissions-click-to-agree {
    margin-bottom: $wds-spacing-l;

    @include body-2-reg;
  }

  .social-permissions-legal-links {
    display: flex;
    gap: $wds-spacing-xs;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: $wds-spacing-m;
  }

  .social-permissions-accept {
    margin: 0 auto $wds-spacing-m;
    width: 100%;
    max-width: 75%;
  }

  .social-permissions-no-participation {
    margin: 0 auto;
  }

  .social-permissions-link {
    text-decoration: underline;

    @include link-2-reg;
  }

  .social-permissions-open-app {
    margin-bottom: $wds-spacing-m;
  }

  @include tablet-large {
    padding: 0 $wds-spacing-l $wds-spacing-l;
    max-width: wds-rem(552px);

    .social-permissions-legal-links {
      flex-direction: row;
      gap: $wds-spacing-xs;
      justify-content: center;
      margin-bottom: $wds-spacing-m;
    }

    .social-permissions-accept {
      margin: 0 auto $wds-spacing-m;
      width: 75%;
    }

    .social-permissions-no-participation {
      margin: 0 auto;
    }
  }
}
</style>
